import React from 'react'
import Img from 'gatsby-image'
import {
  Button,
  Container,
  Divider,
  Grid,
  Header,
  Icon,
  Image,
  List,
  Menu,
  Segment,
  Sidebar,
  Visibility,
} from 'semantic-ui-react'
import styles from './hero.module.css'

export default ({ data }) => (
  <div className={styles.hero}>
    <Img
      className={styles.heroImage}
      alt={data.name}
      fluid={data.heroImage.fluid}
    />
    <div className={styles.heroDetails}>

      <Header
        as='h1'
        content='Be Part of a Winning Team'
        inverted
        style={{
          fontSize: '2em',
          fontWeight: 'normal',
          marginBottom: 0,
          marginTop: '1em',
        }}
      />
      <Header
        as='h2'
        content='The Future of the Independent Agency System.'
        inverted
        className='hero'
        style={{
          fontSize: '1em',
          fontWeight: 'normal',
          marginTop: '0.5em',
          paddingBottom: '2em'
        }}
      />
      <Button href="#membership" secondary color='black' size='large'>
        View Membership Benefits
        <Icon name='right arrow' />
      </Button>
    </div>
  </div>
)
