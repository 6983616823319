import React from 'react'
import { graphql } from 'gatsby'
import get from 'lodash/get'
import { Helmet } from 'react-helmet'
import { Card, Grid, Container, Divider, Image } from 'semantic-ui-react'
import Hero from '../components/hero'
import Layout from '../components/layout'
import ArticlePreview from '../components/article-preview'
import { documentToReactComponents } from '@contentful/rich-text-react-renderer';

const options = {
  renderText: (text) => text.split('\n').reduce((children, textSegment, index) => {
    return [...children, index > 0 && <br key={index} />, textSegment];
  }, [])
};

class RootIndex extends React.Component {
  render() {
    const siteTitle = get(this, 'props.data.site.siteMetadata.title')
    const posts = get(this, 'props.data.allContentfulAgency.edges')
    const carriers = get(this, 'props.data.contentfulCarrier.carriers')
    const otherCarriers = get(this, 'props.data.contentfulCarrier.otherCarriers')
    const contact = get(this, 'props.data.contentfulContactInfo.contact.json')

    const [author] = get(this, 'props.data.allContentfulPerson.edges')
    debugger
    return (
      <Layout location={this.props.location}>
        <div style={{ background: '#fff' }}>
          <Helmet title={siteTitle} />
          <Hero data={author.node} />
          <Container text>
            <Divider
              as="h4"
              className="header"
              horizontal
              style={{ margin: "3em 0em", textTransform: "uppercase" }}
            >
              <div id="about-us">About Us</div>
            </Divider>
            <p style={{ fontSize: "1em" }}>
            Founded in 2011, Summit Insurance Group of PA is an alliance of independent insurance agencies providing our members with increased profitability, carrier access, growth opportunities, and enhanced agency value, all while maintaining agency independence.  Summit members serve the needs of families and businesses throughout western and central Pennsylvania. Favorable carrier relations and profitability has enabled Summit to negotiate preferred agency contracts and compensation for our members.  Continuing a strong profitable partnership with our companies is Summit’s foundation for continued success.  Summit is seeking new members demonstrating consistent profitability, sustainable growth, similar values, and common core carriers.            </p>
            <Divider
              as="h4"
              className="header"
              horizontal
              style={{ margin: "3em 0em", textTransform: "uppercase" }}
            >
              <div id="agencies">Our Agencies</div>
            </Divider>
            <Grid centered stackable columns={2}>
              {posts.map(({ node }) => {
                  return (
                    <Grid.Column>
                      <ArticlePreview article={node} />
                    </Grid.Column>
                  )
                })}
            </Grid>
            <Divider
              as="h4"
              className="header"
              horizontal
              style={{ margin: "3em 0em", textTransform: "uppercase" }}
            >
              <div id="carriers">Our Carriers</div>
            </Divider>
            <Grid centered stackable columns={3}>
              {carriers.map((carrier) => {
                  return (
                    <div style={{maxWidth: '200px', maxWeight: '50px', minWidth: '200px', minWeight: '50px', margin: '16px'}}>
                      <Image src={carrier.fixed.src} alt={carrier.title}></Image>
                    </div>
                  )
                })}
            </Grid>
            <Divider
              as="h4"
              className="header"
              horizontal
              style={{ margin: "3em 0em", textTransform: "uppercase" }}
            >
              <div id="other-carriers">Other Carriers</div>
            </Divider>
            <Grid centered stackable columns={3}>
              {otherCarriers.map((carrier) => {
                  return (
                    <div style={{maxWidth: '150px', maxWeight: '40px', minWidth: '150px', minWeight: '40px', margin: '16px'}}>
                      <Image src={carrier.fixed.src} alt={carrier.title}></Image>
                    </div>
                  )
                })}
            </Grid>

            <Divider
              as="h4"
              className="header"
              horizontal
              style={{ margin: "3em 0em", textTransform: "uppercase" }}
            >
              <div id="membership">Membership Benefits</div>
            </Divider>
            <Grid centered divided stackable columns={2}>
              <Grid.Column>
                <p style={{textAlign: 'center'}}>
                  Access to Top Rated National and Regional Carriers
                  <br></br>
                  Maintain 100% Commission
                  <br></br>
                  Preferred Contracts and Commissions
                  <br></br>
                  Growth Bonus Opportunities
                  <br></br>
                  Maintain Independence
                </p>
              </Grid.Column>
              <Grid.Column>
              <p style={{textAlign: 'center'}}>
                Reasonable Membership Fees
                  <br></br>
                Enhanced Contingency Opportunities
                  <br></br>
                Strength in Numbers
                  <br></br>
                Perpetuation Programs
                  <br></br>
                Carrier Incentives
              </p>
              </Grid.Column>
            </Grid>
            <Divider
              as="h4"
              className="header"
              horizontal
              style={{ margin: "3em 0em", textTransform: "uppercase" }}
            >
              <div id="contact">Contact Us</div>
            </Divider>
            {documentToReactComponents(contact, options)}
            <div style={{paddingBottom: '50px'}}></div>
          </Container>
        </div>
      </Layout>
    )
  }
}

export default RootIndex

export const pageQuery = graphql`
  query HomeQuery {
    site {
      siteMetadata {
        title
      }
    }
    allContentfulAgency(sort: { fields: [title], order: ASC }) {
      edges {
        node {
          title
          slug
          heroImage {
            fluid(maxWidth: 282, maxHeight: 156, resizingBehavior: PAD) {
              ...GatsbyContentfulFluid_tracedSVG
            }
          }
        }
      }
    }
    allContentfulPerson(
      filter: { contentful_id: { eq: "15jwOBqpxqSAOy2eOO4S0m" } }
    ) {
      edges {
        node {
          name
          shortBio {
            shortBio
          }
          title
          heroImage: image {
            fluid(
              resizingBehavior: FILL
            ) {
              ...GatsbyContentfulFluid_tracedSVG
            }
          }
        }
      }
    }
    contentfulCarrier {
      carriers {
        fixed(width: 200, height: 50, resizingBehavior: PAD) {
          src
        }
        title
      }
      otherCarriers {
        fixed(width: 150, height: 40, resizingBehavior: PAD){
          src
        }
        title
      }
    }
    contentfulContactInfo {
      contact {
        json
      }
    }
  }
`
